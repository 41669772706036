
import { defineComponent } from 'vue'
import pageHead from '../components/page-head.vue'
import global from '@/utils/global'
import { WorkerDto } from '@/api/dto'
import { addPeport } from '@/api/report'
import selectPersonnel from '@/views/index/components/select-personnel.vue'
import { fileUpload, getAppName } from '@/api/base'
import { ElUploadRequestOptions } from 'element-plus/lib/components/upload/src/upload.type'
export default defineComponent({
    name: 'ReportAdd',
    components: {
        'page-head': pageHead,
        'select-personnel': selectPersonnel
    },
    data () {
        return {
            info: {
                employeeId: '',
                employeePhone: '',
                type: '',
                is_serious: '',
                is_hospital: '',
                address: '',
                hospital: '',
                desc: '',
                reporterPhone: '',
                caseDate: ''
            },
            isAlert: false,
            selection: [] as WorkerDto[],
            token: '',
            files: [] as {
                name: string
                url: string
                preview: string
                id: string
            }[],
            files2: [] as {
            name: string
            url: string
            preview: string
            id: string
          }[],
            bill: [] as {
            name: string
            url: string
            preview: string
            id: string
          }[],
            previewFiles: [] as string[],
            previewIndex: 0,
            appName: getAppName()
        }
    },
    created () {
        this.token = global.token
    },
    methods: {
        async submit () {
            if (!this.selection.length) {
                this.$message.error('请选择出险人')
                return
            }
            // if (!this.files.length) {
            //     this.$message.error('请上传伤者受伤视频')
            // }
            if (!/^1\d{10}$/.test(this.info.employeePhone)) {
                this.$message.error('请输入11位出险人联系电话')
                return
            }
            if (!/^1\d{10}$/.test(this.info.reporterPhone)) {
                this.$message.error('请输入11位报案人联系电话')
                return
            }
            if (!this.info.caseDate) {
                this.$message.error('请选择出险时间')
                return
            }
            if (!this.info.address) {
                this.$message.error('请输入出险地点')
                return
            }
            if (!this.info.desc) {
                this.$message.error('请输入详细事故经过')
                return
            }
            if (!this.info.type) {
                this.$message.error('请选择事故类型')
                return
            }
            if (this.info.is_serious === '') {
                this.$message.error('请选择是否骨折')
                return
            }
            if (this.info.is_hospital === '') {
                this.$message.error('请选择是否住院')
                return
            }
            const fileIds = this.files.map(item => item.id).concat(this.files2.map(item => item.id))
            const billFileIds = this.bill.map(item => item.id)
            console.log(this.files.map(item => item.id), this.files2.map(item => item.id), billFileIds, 8888)

            if (this.files.map(item => item.id).length === 0 && this.appName === '蓝工盾') {
                this.$message.error('上传伤者受伤图片/视频')
                return
            }
            if (this.files2.map(item => item.id).length === 0 && this.appName === '蓝工盾') {
                this.$message.error('上传受伤部位图片/视频')
                return
            }
            if (billFileIds.length === 0 && this.appName === '蓝工盾') {
                this.$message.error('上传首诊病例图片/视频')
                return
            }
            await addPeport({
                ...this.info,
                employeeId: this.selection[0].employee_id,
                fileIds: fileIds,
                billFileIds: billFileIds
            })
            console.log("this.$message.success('申报成功！')")
            this.$message.success('申报成功，正在为您跳转到列表页！')
            this.$router.push({
                path: '/report'
            })
        },
        // 上传视频
        async fileUpload (file: ElUploadRequestOptions) {
            const FormDatas = new FormData()
            FormDatas.append('iFile', file.file)
            const data = await fileUpload(FormDatas, {
                isLoading: false,
                onUploadProgress: (progressEvent: {
                    loaded: number
                    total: number
                }) => {
                    const num =
                        ((progressEvent.loaded / progressEvent.total) * 100) | 0 // 百分比
                    file.onProgress(({
                        percent: num
                    } as unknown) as ProgressEvent) // 进度条
                    if (num === 100) {
                        file.onSuccess('arraybuffer')
                    }
                }
            })
            this.files.push({
                name: data.fileInfo.file_name,
                url: data.fileInfo.external_url,
                preview: data.fileInfo.preview_url,
                id: data.fileInfo.file_id
            })
            console.log(data)
        },
        async fileUpload2 (file: ElUploadRequestOptions) {
            const FormDatas = new FormData()
            FormDatas.append('iFile', file.file)
            const data = await fileUpload(FormDatas, {
                isLoading: false,
                onUploadProgress: (progressEvent: {
            loaded: number
            total: number
          }) => {
                    const num =
              ((progressEvent.loaded / progressEvent.total) * 100) | 0 // 百分比
                    file.onProgress(({
                        percent: num
                    } as unknown) as ProgressEvent) // 进度条
                    if (num === 100) {
                        file.onSuccess('arraybuffer')
                    }
                }
            })
            this.files2.push({
                name: data.fileInfo.file_name,
                url: data.fileInfo.external_url,
                preview: data.fileInfo.preview_url,
                id: data.fileInfo.file_id
            })
            console.log(data)
        },
        async fileUpload3 (file: ElUploadRequestOptions) {
            const FormDatas = new FormData()
            FormDatas.append('iFile', file.file)
            const data = await fileUpload(FormDatas, {
                isLoading: false,
                onUploadProgress: (progressEvent: {
            loaded: number
            total: number
          }) => {
                    const num =
              ((progressEvent.loaded / progressEvent.total) * 100) | 0 // 百分比
                    file.onProgress(({
                        percent: num
                    } as unknown) as ProgressEvent) // 进度条
                    if (num === 100) {
                        file.onSuccess('arraybuffer')
                    }
                }
            })
            this.bill.push({
                name: data.fileInfo.file_name,
                url: data.fileInfo.external_url,
                preview: data.fileInfo.preview_url,
                id: data.fileInfo.file_id
            })
            console.log(data)
        },
        filePreview (file: { preview: string; name: string }) {
            if (/(jpg)|(png)|(gif)|(jpeg)|(bmp)|(webp)/.test(file.name)) {
                this.previewFiles = [file.preview]
            } else {
                window.location.href = file.preview
            }
        },
        fileRemove (file: { id: string }) {
            console.log(file)
            this.files.forEach(item => {
                if (item.id === file.id) {
                    const index = this.files.indexOf(item)
                    this.files.splice(index, 1)
                }
            })
        },
        fileLimit (file: File) {
            if (file.size / 1024 / 1024 > 20) {
                this.$message.error('请限制视频大小在20M以内')
                throw new Error('请限制视频大小在20M以内')
            }
        },
        isEject () {
            this.isAlert = true
        },
        // 关闭
        close () {
            this.isAlert = false
        },
        confirm (arr: WorkerDto[]) {
            this.isAlert = false
            this.selection = arr
        }
    }
})
